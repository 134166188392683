import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Downshift from 'downshift';
import { useDebounce } from 'use-debounce';
import { fetch } from 'whatwg-fetch';
import Turbolinks from 'turbolinks';

const host = `${document.location.protocol}//${document.location.host}`;

const SearchInput = ({
  defaultIsExpanded
}) => {
  const _paq = window._paq || [] ;

  const [inputSearch, setInputSearch] = useState('');
  const [inputSearchDebounced] = useDebounce(inputSearch, 100);
  const [items, setItems] = useState([]);
  const [isFocused, setIsFocused] = useState(false);

  const buildRequest = ({inputSearch})  => {
    const endpoint = new URL(`${host}/search`);
    const searchParams = new URLSearchParams();

    searchParams.append('search', inputSearch);
    endpoint.search = searchParams.toString();
    return endpoint
  }

  const onFocus = (e) => {
    setIsFocused(true)
    return e
  }
  const onBlur = (e) => {
    setIsFocused(false)
    return e
  }

  const isBottomRadiusVisible = (isOpen) => items.length > 0 && isOpen

  const inputChange = (event) => {
    setInputSearch(event.target.value)
  }

  const searchFoodReference = () => {
    fetch(buildRequest({inputSearch}))
      .then((response) => response.json())
      .then((json) => setItems(json.data));
  }

  const onSelect = (item) => {
    const basePath = (() => {
          switch(item.attributes.content_type) {
            case 'FoodReference':
               return `/aliments/${item.attributes.slug}`;
            case 'Editorial':
              return `/editos/${item.attributes.slug}`;
            default:
              return '/404'
          }
        })()
    _paq.push(['trackEvent', 'Search', 'navigate', basePath]);

    Turbolinks.visit(basePath)
  }

  useEffect(() => {
    if (inputSearchDebounced && inputSearchDebounced.length > 2) {
      searchFoodReference()
    }
  }, [inputSearchDebounced]);

  return (
      <Downshift
        onChange={onSelect}
        itemToString={(item) => (item ? item.value : '')}
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          getToggleButtonProps,
          isOpen,
          inputValue,
          highlightedIndex,
          selectedItem,
          getRootProps,
        }) => (
          <div className='container-search'>
            <label className="visually-hidden" {...getLabelProps()}>Découvrir :</label>
            <div
              {...getRootProps({}, {suppressRefError: true})}
            >
              <input className={`form-control form-control form-control-search search-bordered ${isBottomRadiusVisible(isOpen) ? 'search-bordered-no-bottom' : ''} ${defaultIsExpanded ? 'no-expandable' : 'expandable'} ${isFocused ? 'expanded-focused' : ''}`}
                     placeholder="Rechercher un aliment, un édito..."
                     {...getInputProps({
                      onChange: inputChange,
                      value: inputSearch,
                      onBlur: onBlur,
                      onFocus: onFocus
                     })} />
              <button className='visually-hidden' {...getToggleButtonProps()} aria-label={'toggle menu'}>
                &#8595;
              </button>
              <ul className="search-result-box search-bordered-no-top position-absolute list-group l-0 r-0 p-0 t-100" {...getMenuProps()}>
              {isOpen
                ? items
                    .filter((item) => item.attributes.content_type != 'Activity')
                    .map((item, index) => (
                      <li
                        className="list-group-item goal-search"
                        {...getItemProps({
                          key: item.id,
                          index,
                          item,
                          style: {
                            backgroundColor:
                              highlightedIndex === index ? 'lightgray' : 'white',
                            fontWeight: selectedItem === item ? 'bold' : 'normal',
                          },
                        })}
                      >
                        {item.attributes.name}
                      </li>
                    ))
                : null}
            </ul>
            </div>
          </div>
        )}
      </Downshift>
    )
};


export default SearchInput;
